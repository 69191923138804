<template>

  <Box>
    <Row>
      <Column :width="15">
        <Box :key="redraw">
          <Row v-for="(row, rowIndex) in tableData" :key="rowIndex">

            <CellFactory :rowData="row.data" :showMissingRequirements="showMissingRequirements"
              v-on:withValue="withValue" v-on:withButtonPressed="withButtonPressed" />

          </Row>
        </Box>
      </Column>
    </Row>
  </Box>

</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import SignInRecoveryModel from "./SignInRecoveryModel.js";
import Credentials from "@/domain/model/user/Credentials.js";

import TitleDisplayRow from '@/portals/shared/cell/title/TitleDisplayRow.js';
import CellFactory from "@/portals/shared/cell/CellFactory.vue";
import ConnectionUtils from '@/utils/ConnectionUtils.js';

export default {
  name: "sign-in-recovery-view",
  components: {
    Column, Box, Row,
    CellFactory,
  },
  props: {
  },
  data() {
    return {
      redraw: 1,

      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      CellFactory: CellFactory,
      cookie: null,
      credentials: null,

      tableData: [],
      addModel: null,
      timeout: null,
      ready: false,

      showSavingDialog: false,
      showSessionTimeoutDialog: false,

      TitleDisplayRow: TitleDisplayRow,
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    showMissingRequirements: function () {
      return this.addModel.showMissingRequirements();
    }
  },
  watch: {
    auth_connected() {
      if (this.auth_connected) {
        this.model().send();
      }
    },
    auth_socket_status() {
      if (!this.auth_connected && this.model().isStateSending()) {
        this.model().withErrorUnableToConnectToApi();
        this.populateData();
      } else {
        ConnectionUtils.displayStatus(this);
      }
    },
  },
  mounted: function () {
    this.AuthStorage_updateCompany(null);
    var cookieString = this.$cookie.get("readychek.ca");
    if (cookieString) {
      this.cookie = JSON.parse(cookieString);
      // this.$cookie.delete("readychek.ca");
    }
    this.start();
  },
  methods: {
    ...mapActions([
      'AuthStorage_updateCompany',
      'addListenerAction',
      'removeListenerAction',
      'updateDomain',
      "authClear",
    ]),

    start: function () {
      this.credentials = new Credentials(null);
      if (this.cookie) {
        this.credentials.withEmail(this.cookie.email).withPassword(this.cookie.password);
      }
      this.addModel = new SignInRecoveryModel(this, this.credentials);
      this.model().start();
      this.tableData = this.model().tableData();
      this.ready = true;
    },

    callback: function (msg) {
      this.model().withStatus(msg);
      this.reloadData();
    },

    model: function () {
      return this.addModel;
    },

    withValue: function (kvp) {
      this.model().withValue(kvp);
    },

    withButtonPressed: function (buttonData) {
      this.model().withButtonPressed(buttonData);
    },

    populateData: function () {
      this.tableData = [];
      this.reloadData();
    },

    reloadData: function () {
      this.model().populateData();
      this.tableData = this.model().tableData();
      this.redraw += 1;
    },

    send() {
      this.model().sendIfPossible();
    },

    sendDelayed() {
      setTimeout(this.send, 500);
    },
  },
}
</script>