
import RcDomainObject from "@/domain/common/RcDomainObject.js";
// import ContentUtils from "@/utils/ContentUtils.js";
import StringUtils from "@/utils/StringUtils.js";

export default class Credentials extends RcDomainObject {

  static MODEL_NAME = 'Credentials';
  
  static FIELDS = {
      Email: "email",
      Password: "password",
  }
  
  constructor(domain, data) {
    super(domain, data, Credentials.MODEL_NAME);
  }

  find() {
    return this;
  }
  
  fields() {
    return Credentials.FIELDS;
  }
  
  withPassword(password) {
    this.put(Credentials.FIELDS.Password, password);
    return this;
  }

  password() {
    return this.getString(Credentials.FIELDS.Password);
  }

  hasEmail() {
    var email = this.email();
    if (StringUtils.isEmpty(email)) {
      return false;
    }
    return true;
  }

  withEmail(email) {
    this.put(Credentials.FIELDS.Email, email);
    return this;
  }

  email() {
    return this.getString(Credentials.FIELDS.Email);
  }

}